import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { getQuestionsByArtistId, getTopTipList } from '~components/action';
import {
  AgoraVideoContext,
  SocketContext,
  useUserInfoState,
} from '~utils/container';
import { getHistoryMessages, loginToAgoraVideo } from '~utils/agoraFunctions';
import { showErrorToaster } from '~utils/toasterNotification';
import {
  generateChannelName,
  isTwoStringSame,
  mappingTipData,
  strictValidArray,
  strictValidArrayWithLength,
  strictValidObjectWithKeys,
} from '~utils/commonUtils';
import { FEATURE_FLAGS_TYPE, POPUP_TYPE, USER_TYPE } from '~utils/constants';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { getListQuestions, getUserEvents } from '~api/event';
import { FullModel, RootState, UserInfo, select } from '~stores';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { getBlockedUsers, getTipsHistory } from '~api';
import useAddAvatar from '~hooks/useAddAvatar';
import { useSocket } from '~hooks/useSocket';
import { RematchRootState } from '@rematch/core';
import { RootModel } from '~stores/models';
import { TransactionTipView } from '~types/transaction';
import { createGroupChat, joinChat } from '~api/chats';
import { Container, ScrollBar } from '~components/atoms';
import {
  FanCarousel,
  StreamScreen,
  ArtistHeader,
  ArtistSidebar,
  ArtistSpotlight,
} from '~components/organisms';
import {
  ManageProfileContext,
  ManageProfileProvider,
} from '~providers/ManageProfileProvider';
import useBreakpoints from '~hooks/useBreakpoints';
import { BringToStageProvider } from '~providers/BringToStageProvider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';

import MenuPopupsContainer from '~components/organisms/account/menu-popups-container';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { TABS } from '~components/manage-profile/types';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { toUnixInteger } from '~utils/dateTimeUtils';
import { inMemoryAttendeeTokenService } from '~utils/attendeeUtils';
import { SocketConnection } from '~socket/socket-connection';
// types
import { FanResponse } from '~types/fan';
import { BlockedUser } from '~types/artist';
import { StreamScreenRefProps } from '~components/organisms/stream/stream-screen';
import { EmojiType } from '~components/molecules/emoji-reaction';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import useGa4EventSender from '~hooks/useGa4EventSender';
import UpcomingEventsList from './upcoming-events';
import { ArchivedVideoCardList } from '~components/molecules/archived-video-card';

const Background = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.light,
}));

const StyledScrollBar = styled(ScrollBar)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up(1440)]: {
    padding: 0,
  },
}));

const StyledArtistSpotlightWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(8),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

const StyledDivider = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: -4,
  width: 92,
  height: 4,
  backgroundColor: theme.palette.warning.main,
  [theme.breakpoints.up('md')]: {
    height: 8,
    bottom: -16,
  },
}));

const enum FeatureName {
  MANAGE_PROFILE = 'manage-profile',
}

const User = () => {
  const containerRef = useRef(null);
  const { featureName, extraData } = useParams();
  const { openManageProfile } = useContext(ManageProfileContext);
  const agoraVideo = useContext(AgoraVideoContext);
  const [sSearchParam] = useSearchParams();
  const recording = sSearchParam.get('recording');
  const isRecording = recording === 'true';
  const { isEnable } = useFeatureFlag();
  const isEnableSystemChat = isEnable(
    FEATURE_FLAGS_TYPE.SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW,
  );

  const state = useGlobalState();
  const { isLoginViewer } = useUserInfoState();
  const { isMd } = useBreakpoints();
  const { updateAvatar } = useAddAvatar();
  const {
    config: {
      isFanLive,
      remoteUsers,
      remoteUsersDetails,
      localVideoTrack,
      isArtistLive,
      chatGroupId,
      current_attendies,
    },
    user: { type, username, showLiveCoinPopup, id: userId, originalType },
    artist: {
      currentEvent,
      username: artistUsername,
      tips_chronologically,
      displayUsername,
      id: artist_id,
    },
    rtn_tip,
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = state as any;

  const [fans, setFans] = useState<any[]>([]);
  const dispatch = useGlobalDispatch();
  const { fetchMoreAvatars } = useRematchDispatch(
    (dispatch) => dispatch.common,
  );
  const artistInfo = useSelector<
    RematchRootState<RootModel, FullModel>,
    UserInfo
  >((state) => select.roomInfo.getArtistInfo(state));
  const { id: artistId } = artistInfo || {};
  const socket = useSocket(userId, dispatch) || null;
  const isArtist = originalType === USER_TYPE.ARTIST;
  const { showLoginPopup } = useDispatchPopup();
  const streamScreenRef = useRef<StreamScreenRefProps>(null);
  const ga4Sender = useGa4EventSender();
  const archivedVideos = useSelector((state: RootState) =>
    select.archivedVideos
      .getArchivedVideos(state)
      .filter((video) => video.addToProfile),
  );
  const defaultVideo = useSelector((state: RootState) =>
    select.archivedVideos.getCurrentVideo(state),
  );
  const emitEmoji = (emojiType: EmojiType, text?: string) => {
    if (streamScreenRef.current) {
      streamScreenRef.current.emitEmoji(emojiType, text);
    }
  };
  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_QUESTIONS],
    queryFn: () => getListQuestions(artistId),
    refetchOnWindowFocus: false,
    onSuccess: (data: { questions: FanResponse[] }) => {
      dispatch({ type: 'app', payload: { questionsList: data.questions } });
    },
    enabled: type === USER_TYPE.ARTIST,
  });
  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST, artistId],
    queryFn: () =>
      getBlockedUsers({ page: 1, pageSize: 10, paginate: false, artistId }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data: { data: { data: BlockedUser[] } }) => {
      dispatch({
        type: 'app',
        payload: {
          blockedUsername: data.data.data.map((record) => record.username),
        },
      });
    },
    enabled: isEnableBlockUser,
  });

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_QUESTIONS],
    queryFn: () => getListQuestions(artistId),
    refetchOnWindowFocus: false,
    onSuccess: (data: { questions: FanResponse[] }) => {
      dispatch({ type: 'app', payload: { questionsList: data.questions } });
    },
    enabled: type === USER_TYPE.ARTIST,
  });

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateChronologicalList = (tip: any) => {
    const updatedList = [...tips_chronologically, tip];
    dispatch({
      type: 'artist',
      payload: { tips_chronologically: updatedList },
    });
    getTopTipList(dispatch, state);
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onReceiveTip = async (info: any) => {
    const data = { ...info };
    const { imageUrl, username } = data;
    await updateAvatar(username, imageUrl);
    updateChronologicalList(data);
    dispatch({
      type: 'artist',
      payload: {
        total_earned_in_event: data.total_earned || 0,
      },
    });
  };

  const startChatting = useCallback(
    async () => {
      // add the socket to DependencyList to re-fetch data when reconnecting socket
      if (chatGroupId && socket) {
        await getHistoryMessages(dispatch, chatGroupId, fetchMoreAvatars);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket, chatGroupId, fetchMoreAvatars],
  );

  useEffect(() => {
    startChatting();
  }, [startChatting]);

  useEffect(() => {
    if (strictValidObjectWithKeys(rtn_tip)) onReceiveTip(rtn_tip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtn_tip]);

  useEffect(() => {
    (async () => {
      try {
        if (type && artistId) {
          const res = await getUserEvents(artistId);
          const { upcoming: events, ongoing } = res;
          const { chatId } = artistInfo;
          let groupChatId = chatId;
          if (type === USER_TYPE.ARTIST && !groupChatId) {
            const {
              data: { group },
            } = await createGroupChat();
            groupChatId = group.id;
          }
          // TODO need to define data type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const dataToSave = {} as any;
          dataToSave.isArtistLive = false;
          dataToSave.chatGroupId = groupChatId;
          dataToSave.startLiveAt = ongoing?.event?.createdAt;

          const artist_data = { events, ...artistInfo };
          const currentEvent = ongoing?.event?.id;
          // TODO need to define data type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const payload = artist_data as any;
          payload.currentEvent = '';
          if (currentEvent) {
            payload.currentEvent = currentEvent;
            if (type !== USER_TYPE.ARTIST) dataToSave.isArtistLive = true;
            dataToSave.resumeArtistSession = true;
          }
          dispatch({ type: 'config', payload: dataToSave });
          dispatch({ type: 'artist', payload });
        }
      } catch (err) {
        showErrorToaster(err);
      }
    })();
  }, [artistId, artistInfo, dispatch, type]);

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortData = (fan1: any, fan2: any) => {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fan_1_detail = remoteUsersDetails.find((user: any) =>
      isTwoStringSame(user.username, fan1.uid),
    );
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fan_2_detail = remoteUsersDetails.find((user: any) =>
      isTwoStringSame(user.username, fan2.uid),
    );

    const { allowOnStage: allowOnStage_1 } = fan_1_detail || {};
    const { allowOnStage: allowOnStage_2 } = fan_2_detail || {};
    return allowOnStage_1 === allowOnStage_2 ? 0 : allowOnStage_1 ? -1 : 1;
  };

  const getInitialValues = async () => {
    await getQuestionsByArtistId(artistId, dispatch);
    await getTopTipList(dispatch, state);
  };

  const joinAgoraVideo = async (userNameVideo: string) => {
    if (!agoraVideo) return;

    try {
      await getInitialValues();
      const { localTracks: agoraVideo_localTracks } = agoraVideo || {};
      const channel = generateChannelName(displayUsername, currentEvent);

      if (!strictValidArrayWithLength(agoraVideo_localTracks))
        agoraVideo.setClientRole('audience');
      loginToAgoraVideo(agoraVideo, channel, userNameVideo);
    } catch (error) {
      showErrorToaster(error);
    }
  };

  useEffect(() => {
    let fans_video = [];

    if (strictValidArray(remoteUsers)) {
      fans_video = [...remoteUsers];
      if (
        type === USER_TYPE.ARTIST &&
        strictValidArray(remoteUsersDetails) &&
        remoteUsersDetails.length === fans_video.length
      ) {
        fans_video = fans_video.sort(sortData);
      }

      fans_video = fans_video.filter(
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (user: any) => user.uid !== artistUsername,
      );
      if (type === USER_TYPE.FAN && localVideoTrack) {
        fans_video = [
          { uid: username, videoTrack: localVideoTrack },
          ...fans_video,
        ];
      }
    }
    setFans(fans_video);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localVideoTrack, remoteUsers, isArtistLive, remoteUsersDetails]);

  useEffect(() => {
    if (type === USER_TYPE.FAN && showLiveCoinPopup) {
      dispatch({
        type: 'app',
        payload: {
          popup: POPUP_TYPE.EDIT_PROFILE_POPUP,
        },
      });
    }
  }, [dispatch, showLiveCoinPopup, type]);

  useEffect(() => {
    getTipsHistory(artistId).then((res) => {
      // TODO need check the data type, it should be res.data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { transactions } = res as any;

      const tips = (transactions as TransactionTipView[])?.map((tip) =>
        mappingTipData(tip),
      );
      dispatch({
        type: 'artist',
        payload: { tips_chronologically: tips },
      });
    });
  }, [artistId, dispatch]);

  useEffect(() => {
    // User is the caster
    if (isArtistLive && type !== 'FAN') {
      const livecastId = generateChannelName(displayUsername, currentEvent);
      ga4Sender.sendStartLiveCastEvent(
        username,
        livecastId,
        new Date().toISOString(),
      );

      ga4Sender.sendCurrentAttendeeOnLiveCast(
        username,
        livecastId,
        current_attendies,
      );
    }
    //User is not the cater
    if (isArtistLive && type === 'FAN') {
      ga4Sender.sendSingleUserDataWatchingLivecast(
        artistUsername,
        username,
        generateChannelName(displayUsername, currentEvent),
      );
    }
  }, [isArtistLive, socket, userId, type, originalType, artist_id]);

  useEffect(() => {
    // User is the caster
    if (isArtistLive && type !== 'FAN') {
      const livecastId = generateChannelName(displayUsername, currentEvent);
      ga4Sender.sendCurrentAttendeeOnLiveCast(
        username,
        livecastId,
        current_attendies,
      );
    }
  }, [
    isArtistLive,
    type,
    displayUsername,
    currentEvent,
    username,
    current_attendies,
  ]);

  const startStreaming = useCallback(
    async (eventId: string) => {
      try {
        if (!isArtistLive || !agoraVideo) return;
        if (socket?.getUserId() !== userId) return;

        let agoraUsername;
        if (!userId) {
          agoraUsername = `guest__${toUnixInteger()}`;
        } else if (type === USER_TYPE.FAN && !isFanLive) {
          agoraUsername = username;
        }

        if (!agoraUsername) return;
        // Play streaming video
        await joinAgoraVideo(agoraUsername);
        // Notice server that user is joining the event
        inMemoryAttendeeTokenService.storeAttendeeToken(
          eventId,
          (socket as SocketConnection).currentSession(),
          isRecording,
        );
      } catch (error) {
        return { error };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isArtistLive, agoraVideo, userId, currentEvent, socket, isRecording],
  );

  useEffect(() => {
    startStreaming(currentEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startStreaming]);

  useEffect(() => {
    if (isEnableSystemChat && isLoginViewer && isArtistLive) {
      joinChat(chatGroupId);
    }
  }, [chatGroupId, isArtistLive, isEnableSystemChat, isLoginViewer, type]);

  useEffectOnce(() => {
    if (featureName === FeatureName.MANAGE_PROFILE) {
      if (!username) {
        showLoginPopup();
        return;
      }
      if (isArtist) {
        switch (extraData) {
          case TABS.ADD_LIVECASTS:
            openManageProfile({ initialTab: TABS.ADD_LIVECASTS });
            break;
          case TABS.REWARDS:
            openManageProfile({ initialTab: TABS.REWARDS });
            break;
        }
      }
    }
  });
  return (
    <SocketContext.Provider value={socket}>
      <BringToStageProvider socket={socket} dispatch={dispatch}>
        <Background>
          <Container sx={{ backgroundColor: 'inherit' }} ref={containerRef}>
            <ManageProfileProvider container={containerRef}>
              <ArtistHeader hideLogin={isRecording} emitEmoji={emitEmoji} />
              <MenuPopupsContainer />
              <StyledScrollBar>
                <Grid
                  container
                  spacing={{
                    xs: 0.25,
                    md: 2,
                  }}
                >
                  <Grid item xs={12} lg={7} xl={8}>
                    <StreamScreen ref={streamScreenRef} />
                  </Grid>
                  <Grid item xs={12} lg={5} xl={4}>
                    <ArtistSidebar />
                  </Grid>
                </Grid>
                {isMd && (
                  <StyledArtistSpotlightWrapper>
                    <ArtistSpotlight />
                    {strictValidArrayWithLength(fans) && (
                      <FanCarousel fans={fans} />
                    )}
                  </StyledArtistSpotlightWrapper>
                )}
                <UpcomingEventsList />
                {(defaultVideo || !isMd) && (
                  <Stack
                    pl={{
                      xs: 2,
                      md: 0,
                    }}
                    mb={3}
                    spacing={2}
                  >
                    <Box position={'relative'} mb={3}>
                      <Typography
                        variant="subtitle1"
                        fontSize={{
                          xs: 20,
                          md: 30,
                        }}
                        textTransform={{
                          xs: 'capitalize',
                          md: 'uppercase',
                        }}
                        lineHeight={'39px'}
                        fontWeight={'400'}
                        noWrap
                      >
                        Livecast Videos
                      </Typography>
                      <StyledDivider />
                    </Box>
                    <ArchivedVideoCardList events={archivedVideos} />
                  </Stack>
                )}
              </StyledScrollBar>
            </ManageProfileProvider>
          </Container>
        </Background>
      </BringToStageProvider>
    </SocketContext.Provider>
  );
};

export default User;
