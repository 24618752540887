import { useEffect, useMemo, useState } from 'react';
// @mui
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// utils
import {
  isTwoStringSame,
  strictValidArray,
  strictValidArrayWithLength,
} from '~utils/commonUtils';
import { FEATURE_FLAGS_TYPE, USER_TYPE, useGlobalState } from '~utils/index';
//
import FanCarousel from '../fan-carousel';
import ArtistSpotlightMessage from './artist-spotlight-message';
import ArtistSpotlightViewActions from './artist-spotlight-view-actions';
import ArtistChat from './artist-chat';
import ArtistQuestion from './artist-question';
import ArtistTip from './artist-tip';
import ArtistProfile from './artist-profile';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import ArtistProfileWithArchivedVideos from './artist-profile-with-archived-videos';

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  position: 'absolute',
  top: 0,
  right: 24,
  zIndex: 12,
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  position: 'absolute',
  zIndex: 11,
  backgroundColor: theme.palette.primary.light,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: '0 24px',
}));
const StyledList = styled(List)(() => ({
  height: '44vh',
}));

const ArtistTabsMenu = () => {
  const { isEnable } = useFeatureFlag();
  const isEnableArchivedVideos = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );
  const [currTab, setCurrTab] = useState(0);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const globalState = useGlobalState();
  const [fans, setFans] = useState<any[]>([]);
  const {
    config: { remoteUsers, remoteUsersDetails, localVideoTrack, isArtistLive },
    user: { type, username },
    artist: { username: artistUsername },
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as any;

  const sortData = (fan1: any, fan2: any) => {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fan_1_detail = remoteUsersDetails.find((user: any) =>
      isTwoStringSame(user.username, fan1.uid),
    );
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fan_2_detail = remoteUsersDetails.find((user: any) =>
      isTwoStringSame(user.username, fan2.uid),
    );

    const { allowOnStage: allowOnStage_1 } = fan_1_detail || {};
    const { allowOnStage: allowOnStage_2 } = fan_2_detail || {};
    return allowOnStage_1 === allowOnStage_2 ? 0 : allowOnStage_1 ? -1 : 1;
  };

  const interactionTabs = useMemo(
    () => [
      {
        name: 'Fan Spotlight',
        component: (
          <>
            <Box
              mt={{
                xs: 2.5,
                md: 3,
              }}
              mb={2}
            >
              <ArtistSpotlightMessage />
            </Box>
            <ArtistSpotlightViewActions />
            {strictValidArrayWithLength(fans) && <FanCarousel fans={fans} />}
          </>
        ),
      },
      {
        name: 'Chat',
        component: <ArtistChat />,
      },
      {
        name: 'Questions',
        component: <ArtistQuestion />,
      },
      {
        name: 'Tips',
        component: <ArtistTip />,
      },
      {
        name: 'Artist',
        component: isEnableArchivedVideos ? (
          <ArtistProfileWithArchivedVideos />
        ) : (
          <ArtistProfile />
        ),
      },
    ],
    [fans],
  );

  useEffect(() => {
    if (currTab === 0) {
      let fans_video = [];
      if (strictValidArray(remoteUsers)) {
        fans_video = [...remoteUsers];
        if (
          type === USER_TYPE.ARTIST &&
          strictValidArray(remoteUsersDetails) &&
          remoteUsersDetails.length === fans_video.length
        ) {
          fans_video = fans_video.sort(sortData);
        }

        fans_video = fans_video.filter(
          // TODO need to define data type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (user: any) => user.uid !== artistUsername,
        );
        if (type === USER_TYPE.FAN && localVideoTrack) {
          fans_video = [
            { uid: username, videoTrack: localVideoTrack },
            ...fans_video,
          ];
        }
      }
      setFans(fans_video);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currTab, localVideoTrack, remoteUsers, isArtistLive, remoteUsersDetails]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="body1" fontSize={{ xs: 20 }} fontWeight={'400'}>
          {interactionTabs[currTab].name}
        </Typography>
      </Stack>
      <StyledIconButton
        size="small"
        disableTouchRipple
        disableRipple
        onClick={() => {
          setToggleDrawer(!toggleDrawer);
        }}
      >
        {toggleDrawer ? (
          <CloseOutlinedIcon
            sx={{
              width: 30,
              height: 30,
              color: (theme) => theme.palette.common.white,
            }}
          />
        ) : (
          <ReorderIcon
            sx={{
              width: 30,
              height: 30,
              color: (theme) => theme.palette.common.white,
            }}
          />
        )}
      </StyledIconButton>
      <StyledCollapse in={toggleDrawer}>
        <StyledList>
          {interactionTabs
            .map((tab) => tab.name)
            .map((value, index) => (
              <ListItemButton
                key={value}
                divider={value !== 'Artist'}
                sx={{ px: 0 }}
                onClick={() => {
                  setCurrTab(index);
                  setToggleDrawer(false);
                }}
              >
                <ListItemText
                  primary={value}
                  primaryTypographyProps={{ fontWeight: '500' }}
                />
              </ListItemButton>
            ))}
        </StyledList>
      </StyledCollapse>
      {interactionTabs[currTab].component}
    </>
  );
};

export default ArtistTabsMenu;
