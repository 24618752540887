import React, { useState } from 'react';
// @mui
import { Tab, Tabs, alpha, styled } from '@mui/material';
//
import ArtistChat from './artist-chat';
import ArtistQuestion from './artist-question';
import ArtistTip from './artist-tip';
import ArtistProfile from './artist-profile';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { FEATURE_FLAGS_TYPE } from '~utils/constants';
import ArtistProfileWithArchivedVideos from './artist-profile-with-archived-videos';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: 'none',
  borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const ArtistTabs = () => {
  const { isEnable } = useFeatureFlag();
  const isEnableArchivedVideos = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );
  const [currTab, setCurrTab] = useState(0);

  const interactionTabs = [
    { name: 'Chat', component: <ArtistChat /> },
    { name: 'Questions', component: <ArtistQuestion /> },
    { name: 'Tips', component: <ArtistTip /> },
    {
      name: 'Artist',
      component: isEnableArchivedVideos ? (
        <ArtistProfileWithArchivedVideos />
      ) : (
        <ArtistProfile />
      ),
    },
  ];

  return (
    <>
      <StyledTabs
        variant="fullWidth"
        value={currTab}
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrTab(value)}
      >
        {interactionTabs.map((item, i) => (
          <Tab
            sx={{
              fontSize: (theme) => theme.typography.fontSize,
              fontWeight: '500',
            }}
            key={`artist-tab-${i}`}
            label={item.name}
            value={i}
          />
        ))}
      </StyledTabs>
      {interactionTabs[currTab].component}
    </>
  );
};

export default ArtistTabs;
