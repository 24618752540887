import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  Stack,
  styled,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { Circle, MoreHoriz } from '@mui/icons-material';
import { getImageURL } from '~utils/awsUtils';
import {
  SlideRatioContainer,
  SlideBorder,
} from '~components/pages/home/styles';
import { ThumbNail } from '~components/pages/home/ThumbNail';
import { Event } from '~types/event';
import {
  addOrRemoveArchivedVideoProfile,
  setOrUnsetArchivedVideoAsDefault,
} from '~api/archived-videos';
import { showErrorToaster } from '~utils/toasterNotification';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import moment from 'moment';
import { RemoveArchivedVideoConfirmationModal } from '~components/organisms';

type Props = {
  data: Event;
  onRefreshEventList: any;
} & BoxProps;

export const ItemInSlide = ({
  data,
  onRefreshEventList,
  ...boxProps
}: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { showPreviewVideo } = useDispatchPopup();
  const imageUrl = getImageURL(data.imageUrl);
  const { videoTitle, addToProfile, defaultOnProfile, name, videoUrl } = data;
  const MENU_ITEM_SET_AS_DEFAULT = 'Set as default';
  const MENU_ITEM_ADD_TO_PROFILE = 'Add to Profile';
  const MENU_ITEM_HIDE_VIDEO = 'Hide Video from Profile';
  const MENU_ITEM_REMOVE_AS_DEFAULT = 'Remove as default video';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const OPTIONS = defaultOnProfile
    ? [MENU_ITEM_REMOVE_AS_DEFAULT]
    : addToProfile
    ? [MENU_ITEM_SET_AS_DEFAULT, MENU_ITEM_HIDE_VIDEO]
    : [MENU_ITEM_ADD_TO_PROFILE];

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const duration = moment
    .utc(
      Math.abs(
        moment(new Date(data.startedAt)).diff(
          new Date(data.endedAt),
          'milliseconds',
        ),
      ),
    )
    .format('HH:mm:ss');

  const handleOptionClick = async (option: string) => {
    try {
      let updatedEvent;
      switch (option) {
        case MENU_ITEM_ADD_TO_PROFILE:
          updatedEvent = (await addOrRemoveArchivedVideoProfile(data.id, true))
            .data;
          break;
        case MENU_ITEM_HIDE_VIDEO:
          setOpenConfirm(true);
          break;
        case MENU_ITEM_REMOVE_AS_DEFAULT:
          updatedEvent = (
            await setOrUnsetArchivedVideoAsDefault(data.id, false)
          ).data;
          break;
        case MENU_ITEM_SET_AS_DEFAULT:
          updatedEvent = (await setOrUnsetArchivedVideoAsDefault(data.id, true))
            .data;
          break;
        default:
          break;
      }
      onRefreshEventList(updatedEvent);
    } catch (e) {
      showErrorToaster(e, {
        style: {
          zIndex: 1000,
        },
      });
      throw e;
    }
    handleMenuClose();
  };

  const previewVideo = () => {
    showPreviewVideo(
      `${process.env.REACT_APP_AWS_S3_IMAGE_BUCKET_ENDPOINT}/${videoUrl}`,
    );
  };

  const onConfirmRemove = async () => {
    const updatedEvent = (await addOrRemoveArchivedVideoProfile(data.id, false))
      .data;
    onRefreshEventList(updatedEvent);
    setOpenConfirm(false);
  };

  const onCancelRemove = () => {
    setOpenConfirm(false);
  };

  return (
    <Box {...boxProps}>
      <SlideBorder style={{ cursor: 'pointer' }} onClick={previewVideo}>
        <SlideRatioContainer>
          <ThumbNail src={imageUrl} />
          <SliderContent>
            {defaultOnProfile && (
              <Typography
                padding={1}
                borderRadius={2}
                color={'black'}
                bgcolor={'#fac8a3'}
              >
                Default Video
              </Typography>
            )}
            {/* spacing */}
            <div style={{ height: '100%' }} />
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'end'}
              bgcolor={'rgba(0, 0, 0, 0.5)'}
              padding={1}
            >
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {moment(new Date(data.scheduledAt)).format('MM/DD/YYYY')}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {duration}
              </Typography>
            </Stack>
          </SliderContent>
        </SlideRatioContainer>
      </SlideBorder>
      <Stack
        width={'100%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginTop={'auto'}
      >
        <Stack alignItems={'center'} direction={'row'}>
          <Circle
            style={{ color: addToProfile ? 'green' : 'orange', fontSize: 10 }}
          />
          <Typography
            sx={{
              paddingLeft: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {videoTitle ?? name}
          </Typography>
        </Stack>
        <IconButton onClick={handleMenuOpen}>
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {OPTIONS.map((option, index) => (
            <MenuItem key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <RemoveArchivedVideoConfirmationModal
        open={openConfirm}
        onConfirm={onConfirmRemove}
        onCancelRemove={onCancelRemove}
      />
    </Box>
  );
};

const SliderContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 12,
}));
